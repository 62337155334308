<template>
  <form-wizard
    ref="outboundFormWizard"
    color="rgba(var(--vs-primary), 1)"
    :title="title"
    :subtitle="lang.newChat.wizard.subtitle[languageSelected]"
    :nextButtonText="lang.newChat.wizard.next[languageSelected]"
    :backButtonText="lang.newChat.wizard.back[languageSelected]"
    :finishButtonText="lang.newChat.wizard.finish[languageSelected]"
    @on-complete="createChat"
    id="button-with-loading"
    class="new-chat vs-con-loading__container"
    stepSize="xs"
  >
    <!-- step 1 -->
    <tab-content
      class="mb-5"
      icon="feather icon-command"
      :title="lang.newChat.wizard.platform[languageSelected]"
      :before-change="loadBots"
    >
      <vs-card>
        <h1>
          <strong>
            {{ lang.audiences.add.wizard.step[languageSelected] }}
            1:
          </strong>
          {{ lang.newChat.wizard.platform[languageSelected] }}
        </h1>
        <div class="vx-row flex justify-center">
          <!-- <vs-col vs-justify="center" vs-align="center" vs-w="12">
            <ul class="mt-3">
              <li v-for="(item, index) in platforms" :key="index">
                <vs-radio
                  v-model="platform"
                  :disabled="item.disabled"
                  :vs-value="item.value"
                  >{{ item.text }}</vs-radio
                >
              </li>
            </ul>
          </vs-col> -->

          <vs-select v-model="platform" class="mt-5">
            <vs-select-item
              v-for="(item, index) in platforms"
              :key="index"
              :value="item.value"
              :text="item.text"
              :disabled="item.enabled"
              class="platform"
            />
          </vs-select>
        </div>
      </vs-card>
    </tab-content>

    <!-- step 2 -->
    <tab-content
      class="mb-5"
      icon="feather icon-box"
      :title="lang.newChat.wizard.bot[languageSelected]"
      :before-change="validateStep2"
    >
      <vs-card>
        <h1>
          <strong>
            {{ lang.audiences.add.wizard.step[languageSelected] }}
            2:
          </strong>
          {{ lang.newChat.wizard.bot[languageSelected] }}
        </h1>
        <div class="vx-row">
          <vs-col vs-justify="center" vs-align="center" vs-w="12">
            <Bots :platform="platform" :setBot="setBot" :date="date" />
            <!-- <BotSelector
            :bots="outgoingBots"
            v-if="outgoingBots"
            @onSelect="selectOutgoingBot" 
            /> -->
          </vs-col>
        </div>
        <p class="error-message" v-show="validations.step2.emptyBot">
          {{
            lang.audiences.add.wizard.validations.emptyIntegration[
              languageSelected
            ]
          }}
        </p>
      </vs-card>
    </tab-content>

    <!-- step 3 -->
    <tab-content
      class="mb-5"
      icon="feather icon-message-circle"
      :title="lang.newChat.wizard.messageTitle[languageSelected]"
    >
      <vs-card>
        <h1>
          <strong>
            {{ lang.audiences.add.wizard.step[languageSelected] }}
            3:
          </strong>
          {{ lang.newChat.wizard.messageTitle[languageSelected] }}
        </h1>
        <div class="vx-row">
          <vs-col vs-justify="center" vs-align="center" vs-w="12">
            <vs-input
              :label="lang.newChat.wizard.number[languageSelected]"
              class="mb-5 w-full"
              :placeholder="
                lang.newChat.wizard.messagePlaceholder[languageSelected]
              "
              v-model="senderId"
            />
            <vs-textarea
              v-model="message"
              :label="lang.newChat.wizard.message[languageSelected]"
              :placeholder="lang.newChat.wizard.message[languageSelected]"
            />
          </vs-col>
        </div>
      </vs-card>
    </tab-content>
  </form-wizard>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex'
import { FormWizard, TabContent } from 'vue-form-wizard'
import ChatService from '../../../../../../src/services/chat.service.js'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'

export default {
  name: 'NewChatWizard',
  props: {
    title: {
      type: String,
      default: ''
    },
    successRedirect: {
      type: String,
      default: '/attention-requests'
    }
  },
  components: {
    FormWizard,
    TabContent,
    Bots: () => import('./Bots')
    // BotSelector: () =>
    //   import('../../../diffusions/diffusion/components/BotSelector.vue')
  },
  data() {
    return {
      date: new Date().getTime(),
      text: '',
      // show: false,
      platform: 'whatsapp',
      platforms: [
        { text: 'WhatsApp', value: 'whatsapp', disabled: false },
        { text: 'RCS', value: 'rcs', disabled: true }
      ],
      bot: '',
      senderId: '',
      message: '',
      outgoingBots: [],
      validations: {
        step2: {
          emptyBot: false
        }
      }
    }
  },
  // watch: {
  //   popup(newVal) {
  //     this.show = newVal
  //     if (this.show) {
  //       this.$refs.outboundFormWizard.reset()
  //       this.platform = 'whatsapp'
  //       this.message = ''
  //       this.senderId = ''
  //       this.date = new Date().getTime()
  //     }
  //   }
  // },
  computed: {
    ...mapState(['lang', 'languageSelected']),
    ...mapGetters('bots', ['getBots']),
    ...mapGetters('integrations', ['integrations'])
  },
  methods: {
    ...mapActions('bots', ['GET_BOTS']),
    ...mapActions('integrations', ['GET_INTEGRATIONS']),
    reset() {
      this.platform = 'whatsapp'
      this.message = ''
      this.senderId = ''
      this.date = new Date().getTime()
    },
    createChat() {
      if (!this.bot) {
        this.$vs.notify({
          title: this.lang.newChat.error.bot.validationFail.title[
            this.languageSelected
          ],
          text: this.lang.newChat.error.bot.validationFail.text[
            this.languageSelected
          ],
          color: 'danger',
          position: 'top-right'
        })
        return
      }
      const senderId = (this.senderId.match(/\d+/g) || []).join('')
      if (!/^\d+$/.test(senderId)) {
        this.$vs.notify({
          title: this.lang.newChat.error.senderId.validationFail.title[
            this.languageSelected
          ],
          text: this.lang.newChat.error.senderId.validationFail.text[
            this.languageSelected
          ],
          color: 'danger',
          position: 'top-right'
        })
        return
      }
      if (
        !this.message ||
        this.message.replace(/\n/g, ' ').trim().length === 0
      ) {
        this.$vs.notify({
          title: this.lang.newChat.error.message.validationFail.title[
            this.languageSelected
          ],
          text: this.lang.newChat.error.message.validationFail.text[
            this.languageSelected
          ],
          color: 'danger',
          position: 'top-right'
        })
        return
      }
      this.$vs.loading({
        container: '#button-with-loading',
        scale: 1
      })
      const body = {
        platform: this.platform,
        bot: this.bot,
        senderId,
        message: this.message.replace(/\n/g, ' ').trim()
      }
      return ChatService.createChat(body)
        .then(response => {
          this.$vs.loading.close('#button-with-loading > .con-vs-loading')
          this.changeView(response.data.bot, response.data._id)
        })
        .catch(error => {
          console.error(error)
          this.$vs.loading.close('#button-with-loading > .con-vs-loading')
          this.$vs.notify({
            title: this.lang.newChat.error.generic.validationFail.title[
              this.languageSelected
            ],
            text: this.lang.newChat.error.generic.validationFail.text[
              this.languageSelected
            ],
            color: 'danger',
            position: 'top-right'
          })
        })
        .finally(() => {
          this.$emit('onFinish')
        })
    },
    setBot(bot) {
      this.bot = bot
      this.validateStep2()
    },
    changeView(bot, chat) {
      let routeData = this.$router.resolve({
        path: this.successRedirect,
        query: {
          bot: bot,
          chat: chat
        }
      })
      window.open(routeData.href, '_blank')
    },
    getOutgoingBots() {
      let botsByServiceId = {}
      this.getBots.forEach(element => {
        botsByServiceId[element.service.id] = element
      })

      this.outgoingBots = this.integrations.filter(
        i => botsByServiceId[i.service._id]
      )
    },
    // selectOutgoingBot(bot) {
    // this.SET_PUSH_INTEGRATION(bot._id)
    // this.navigateToStep(2)
    // },
    async loadBots() {
      this.$vs.loading()
      let isValid = true
      try {
        let getTypes = [this.platform];
        if (this.platform === 'whatsapp') {
           getTypes.push('whatsapp_infobip');
        }
        const payload = { types: getTypes }
        await this.GET_INTEGRATIONS(payload)
        await this.GET_BOTS()
        this.getOutgoingBots()
      } catch (error) {
        isValid = false
        console.warn(error)
      } finally {
        this.$vs.loading.close()
      }
      return isValid
    },
    validateStep2() {
      this.validations.step2.emptyBot = !this.bot
      return !this.validations.step2.emptyBot
    }
  }
}
</script>

<style lang="scss">
.new-chat {
  .vs-card--content {
    padding: 20px;
    h1 {
      font-size: 1.2rem;
      padding-bottom: 20px;
      text-align: center;
    }
    .error-message {
      margin-top: 15px;
      display: block;
      height: 19px;
      color: rgba(var(--vs-danger), 1);
      font-size: 1rem;
      padding-left: 30px;
    }
    .vs-textarea:focus {
      border: solid 1px rgba(var(--vs-primary), 1) !important;
      box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.15);
    }
  }
  @media screen and (max-height: 768px) {
    &.vue-form-wizard {
      padding-bottom: 0;

      .wizard-navigation {
        .wizard-tab-content {
          padding: 10px 20px 10px;

          .wizard-tab-container {
            margin-bottom: 0px !important;

            .all-bots-view {
              margin-bottom: 10px !important;

              .bot-item {
                margin-bottom: 0;
              }
            }
          }
        }
      }
    }
  }
}
</style>
